import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { DateTime } from "luxon"

class ItemNews extends React.Component {
  render() {
    return (
      <Link className="item is-angle-right" to={this.props.to}>
        <div className="inner is-padding-sm">
          <div className="grid is-middle">
            <div className="col">
              <span className="icon is-fit-lg is-dark-5">
                <FontAwesomeIcon className="icon" icon={["fas", "feather"]} />
              </span>
            </div>
            <div className="col is-mobile-0 is-space-quarter">
              <p className="text is-dark-2 is-space-right-half is-xs">
                <span className="text is-inline-block">
                  <FontAwesomeIcon
                    className="icon"
                    icon={["far", "calendar-alt"]}
                  />
                  <span className="text" />
                  <span className="text">
                    {DateTime.fromISO(this.props.publishedAt, {
                      zone: "Asia/Tokyo"
                    }).toFormat("yyyy/MM/dd")}
                  </span>
                </span>
                <span className="text">{this.props.title}</span>
              </p>
            </div>
          </div>
        </div>
      </Link>
    )
  }
}

export default ItemNews
