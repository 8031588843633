import React from "react"
import { graphql } from "gatsby"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//import { DateTime } from "luxon"

import Layout from "components/Layout"
import Head from "components/Head"
import ItemNews from "components/ItemNews"
import ItemTip from "components/ItemTip"
import CardItem from "components/CardItem"

import hero from "images/hero.jpg"

//const pkg = require("root/package.json")
const pjt = require("root/project.json")

const IndexPage = ({ data }) => (
  <Layout>
    <Head id="home" />
    <section className="section is-items is-space">
      <div className="box is-space-half">
        <div className="card is-overflow-hidden">
          <div className="card-header">
            <div className="inner is-padding-xs">
              <h2 className="text is-center is-sm">{pjt.site.heading}</h2>
            </div>
          </div>
          <img src={hero} alt="Hero" className="img is-mobile-full" />
        </div>
      </div>
      {
        //<div className="box is-space-half">
        //  <div className="card is-separate">
        //    <div className="card-header">
        //      <div className="inner is-padding-xs">
        //        <h2 className="text is-strong is-sm">お知らせ</h2>
        //      </div>
        //    </div>
        //    <ul className="card-list is-border">
        //      {data.news.edges.map((edge, i) => (
        //        <li key={i}>
        //          <ItemNews
        //            to={`/news/${edge.node.data.slug}/`}
        //            src={
        //              edge.node.data.images
        //                ? edge.node.data.images[0].thumbnails.small.url
        //                : null
        //            }
        //            title={edge.node.data.title}
        //            publishedAt={edge.node.data.publishedAt}
        //          />
        //        </li>
        //      ))}
        //    </ul>
        //  </div>
        //</div>
      }
      <div className="box is-space-half">
        <div className="card is-separate">
          <div className="card-header">
            <div className="inner is-padding-xs">
              <h2 className="text is-strong is-sm">便利な使い方</h2>
            </div>
          </div>
          <ul className="card-list is-border">
            {data.tips.edges.map((edge, i) => (
              <li key={i}>
                <ItemTip
                  to={`/tips/${edge.node.data.slug}/`}
                  src={
                    edge.node.data.images
                      ? edge.node.data.images[0].thumbnails.small.url
                      : null
                  }
                  title={edge.node.data.title}
                  publishedAt={edge.node.data.publishedAt}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="box is-space-half">
        <ul className="grid is-stretch">
          {data.items.edges.map((edge, i) => (
            <li
              className="col is-mobile-12 is-tablet-6 is-desktop-6 is-wide-4"
              key={i}
            >
              <CardItem
                to={`/items/${edge.node.data.slug}/`}
                src={edge.node.data.images[0].thumbnails.large.url}
                title={edge.node.data.title}
                imageCount={edge.node.data.images.length}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    items: allAirtable(
      filter: { table: { eq: "items" }, data: { published: { eq: true } } }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            categories {
              data {
                name
              }
            }
            images {
              url
              thumbnails {
                large {
                  url
                }
              }
            }
          }
        }
      }
    }
    tips: allAirtable(
      limit: 3
      filter: { table: { eq: "tips" }, data: { published: { eq: true } } }
      sort: { fields: [data___publishedAt], order: DESC }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            publishedAt
            images {
              url
              thumbnails {
                small {
                  url
                }
              }
            }
          }
        }
      }
    }
    news: allAirtable(
      limit: 3
      filter: { table: { eq: "news" }, data: { published: { eq: true } } }
      sort: { fields: [data___publishedAt], order: DESC }
    ) {
      edges {
        node {
          data {
            title
            published
            slug
            publishedAt
            images {
              url
              thumbnails {
                small {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`
